

const Nav = ({className, item}) => {
  return (
    <li clasName={className}>
      <a href={item.link}>
        {item.icon}
      </a>
    </li>
  )
}

export default Nav
