import data from './data'
import './floating-nav.css'
/*import Scrollspy from 'react-scrollspy'*/
import Nav from './Nav'

const FloatingNav = () => {
    return (
        <ul id='floating__nav'>
            <section offset={-400} className='scrollspy' items={['header','about','skills','portfolio','contact']} currentClassName="active">
                {
                    data.map(item => <Nav key={item.id} item={item} />)
                }
            </section>
        </ul>
    )
}
export default FloatingNav