const data = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#skills', title: 'Services'},
    {id: 4, link: '#portfolio', title: 'Company Portfolio'},
    {id: 5, link: '#testimonials', title: 'Testimonials'},
    {id: 6, link: '#education', title: 'Team'},
    {id: 7, link: '#contact', title: 'Contact'}
]

export default data;