import {AiOutlineInstagram, AiOutlineLinkedin} from 'react-icons/ai';
import {AiOutlineTwitter} from 'react-icons/ai';
import {AiOutlineDribbble} from 'react-icons/ai';
import {AiFillGithub} from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.instagram.com/jacobo.r.velasco/', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com/?lang=es', icon: <AiOutlineTwitter/>},
    {id: 3, link: 'https://www.linkedin.com/in/jacob-ruiz-03324884/', icon: <AiOutlineLinkedin/>},
    {id: 4, link: 'https://github.com/Jacobo1978/Squash_Project_Portfolio', icon: <AiFillGithub/>}
]

export default data