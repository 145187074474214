import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'

const data = [
    {
        id: 1,
        category: 'Constuction',
        image: Image1,
        title: "Calgary Tower A",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis elementum nibh, vitae viverra massa. Nunc ultricies lorem sit amet metus blandit, et venenatis lorem cursus. Nam rhoncus est et magna sagittis, blandit eleifend felis placerat. Mauris convallis sapien at efficitur facilisis. Pellentesque rutrum velit in rhoncus luctus. Pellentesque euismod aliquam augue, eget convallis purus malesuada eu. Aliquam erat volutpat. Nulla sodales massa in sem condimentum, non imperdiet lorem commodo. Suspendisse bibendum auctor interdum. Etiam iaculis magna ac magna egestas efficitur. In id ligula et ante egestas mattis nec in orci. Maecenas eu est eu augue porttitor varius.",
        demo: 'http://embracingapps.com',
        github: 'https://github.com/Jacobo1978/Squash_Project_Portfolio'
    },
    {
        id: 2,
        category: 'Services',
        image: Image2,
        title: "Birkby Food Services",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis elementum nibh, vitae viverra massa. Nunc ultricies lorem sit amet metus blandit, et venenatis lorem cursus. Nam rhoncus est et magna sagittis, blandit eleifend felis placerat. Mauris convallis sapien at efficitur facilisis. Pellentesque rutrum velit in rhoncus luctus. Pellentesque euismod aliquam augue, eget convallis purus malesuada eu. Aliquam erat volutpat. Nulla sodales massa in sem condimentum, non imperdiet lorem commodo. Suspendisse bibendum auctor interdum. Etiam iaculis magna ac magna egestas efficitur. In id ligula et ante egestas mattis nec in orci. Maecenas eu est eu augue porttitor varius.",
        demo: 'http://embracingapps.com',
        github: 'https://github.com/Jacobo1978/Squash_Project_Portfolio'
    },
    {
        id: 3,
        category: 'Services',
        image: Image3,
        title: "Daystar Mechanical",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis elementum nibh, vitae viverra massa. Nunc ultricies lorem sit amet metus blandit, et venenatis lorem cursus. Nam rhoncus est et magna sagittis, blandit eleifend felis placerat. Mauris convallis sapien at efficitur facilisis. Pellentesque rutrum velit in rhoncus luctus. Pellentesque euismod aliquam augue, eget convallis purus malesuada eu. Aliquam erat volutpat. Nulla sodales massa in sem condimentum, non imperdiet lorem commodo. Suspendisse bibendum auctor interdum. Etiam iaculis magna ac magna egestas efficitur. In id ligula et ante egestas mattis nec in orci. Maecenas eu est eu augue porttitor varius.",
        demo: 'http://embracingapps.com',
        github: 'https://github.com/Jacobo1978/Squash_Project_Portfolio'
    },
    {
        id: 4,
        category: 'Constuction',
        image: Image4,
        title: "Eau Claire",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis elementum nibh, vitae viverra massa. Nunc ultricies lorem sit amet metus blandit, et venenatis lorem cursus. Nam rhoncus est et magna sagittis, blandit eleifend felis placerat. Mauris convallis sapien at efficitur facilisis. Pellentesque rutrum velit in rhoncus luctus. Pellentesque euismod aliquam augue, eget convallis purus malesuada eu. Aliquam erat volutpat. Nulla sodales massa in sem condimentum, non imperdiet lorem commodo. Suspendisse bibendum auctor interdum. Etiam iaculis magna ac magna egestas efficitur. In id ligula et ante egestas mattis nec in orci. Maecenas eu est eu augue porttitor varius.",
        demo: 'http://embracingapps.com',
        github: 'https://github.com/Jacobo1978/Squash_Project_Portfolio'
    },
    {
        id: 5,
        category: 'Constuction',
        image: Image5,
        title: "Trico Homes",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis elementum nibh, vitae viverra massa. Nunc ultricies lorem sit amet metus blandit, et venenatis lorem cursus. Nam rhoncus est et magna sagittis, blandit eleifend felis placerat. Mauris convallis sapien at efficitur facilisis. Pellentesque rutrum velit in rhoncus luctus. Pellentesque euismod aliquam augue, eget convallis purus malesuada eu. Aliquam erat volutpat. Nulla sodales massa in sem condimentum, non imperdiet lorem commodo. Suspendisse bibendum auctor interdum. Etiam iaculis magna ac magna egestas efficitur. In id ligula et ante egestas mattis nec in orci. Maecenas eu est eu augue porttitor varius.",
        demo: 'http://embracingapps.com',
        github: 'https://github.com/Jacobo1978/Squash_Project_Portfolio'
    },
    {
        id: 6,
        category: 'Design',
        image: Image6,
        title: "Calgary Economic Development",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis elementum nibh, vitae viverra massa. Nunc ultricies lorem sit amet metus blandit, et venenatis lorem cursus. Nam rhoncus est et magna sagittis, blandit eleifend felis placerat. Mauris convallis sapien at efficitur facilisis. Pellentesque rutrum velit in rhoncus luctus. Pellentesque euismod aliquam augue, eget convallis purus malesuada eu. Aliquam erat volutpat. Nulla sodales massa in sem condimentum, non imperdiet lorem commodo. Suspendisse bibendum auctor interdum. Etiam iaculis magna ac magna egestas efficitur. In id ligula et ante egestas mattis nec in orci. Maecenas eu est eu augue porttitor varius.",
        demo: 'http://embracingapps.com',
        github: 'https://github.com/Jacobo1978/Squash_Project_Portfolio'
    },

]


export default data