import './navbar.css'
import data from './data.js'
import Profile_Pic from '../../assets/Neumaier_logo.jpeg'
//import {IoIosColorPalette} from 'react-icons/io'
import {useModalContext} from '../../context/modal-context.jsx'

//<button id='theme__icon' onClick={showModalHandler}><IoIosColorPalette/></button>

const Navbar = () => {
  const {showModalHandler} = useModalContext();

  return (
    <nav id="navbar">
      <div className="container nav__container">
        <a href="index.html" className='nav__logo'>
          <img src={Profile_Pic} alt="Profile_Pic" />
        </a>
        <ul className='nav__menu'>
          {
            data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
          }
        </ul>
        
      </div>
    </nav>
  )
}

export default Navbar
