import './header.css'
import Profile_Pic from '../../assets/Neumaier_logo.jpeg'
import data from './data'


const Header = () => {
  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile">
          <img src={Profile_Pic} alt="Header Portrait" />
        </div>
        <h2>Neumaier Services Admin and Operations</h2>
        <p>
        We are your one-stop-shop for all things operations, go-to-market strategy and end-to-end custoer journey service management.
        </p>
        <div className="header__cta">
          <a href="#contact" className='btn primary'>Contact us</a>
          <a href="#portfolio" className='btn primary'>Company Portfolio</a>
        </div>
        <div className="header__socials">
          {
            data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
    </header>
  )
}

export default Header