import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '8 years working with construciton companies.'},
    {id: 2, icon: <TbBooks/>, title: 'Project Manager', desc: '50+ Projects Completed'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Customers', desc: '100+ happy customers'}
    ]

    export default data;