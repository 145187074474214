import AboutImage from '../../assets/about.jpg'
import './about.css'
import {HiDownload} from 'react-icons/hi'
import NS from '../../assets/NS.pdf'
import Card from '../../components/Card'
import data from './data'



const About = () => {
  return (
    <section id="about">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h2>Who we are</h2>
                <div className="about__fichas">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__ficha">
                                <span className='about__ficha-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                One-stop-shop and on-site Operations for construction, troubleshooting, cleaning and cleansing.
                </p>
                <p>
                Count on us with trades such as: Cleaning, cleansing, plumbing, heating, painting, electical, handyman services.
                </p>
                <a href={NS} download className='btn primary'>Download list of services<HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About