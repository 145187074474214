import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.jpg'
import Avatar3 from '../../assets/avatar3.jpg'
import Avatar4 from '../../assets/avatar4.jpg'
import Avatar5 from '../../assets/avatar5.jpg'
import Avatar6 from '../../assets/avatar6.jpg'
import Avatar7 from '../../assets/avatar7.jpg'


const data = [
        {
        id: 1,
        quote: "Highly recommended, Neumaier is an excellent operations company to work with!",
        avatar: Avatar1,
        name: 'John Smith',
        profession: 'CEO'
        },
        {
        id: 2,
        quote: "Neumaier is a determining company, people from Neumaier Services are stubborn and very efficient",
        avatar: Avatar2,
        name: 'Ernesto Martinez',
        profession: 'Electronics Engineer'
        },
        {
        id: 3,
        quote: "Impressive work. Highly recommend!",
        avatar: Avatar3,
        name: 'Xavi Belmont',
        profession: 'General Contractor'
        },
        {
        id: 4,
        quote: "Neumaier Services with excellent Project Managers with lots of experience in the industry",
        avatar: Avatar4,
        name: 'Venki',
        profession: 'Civil Engineer'
        },
        {
        id: 5,
        quote: "Great company, always giving solutions, with great sense of eficiency and effectiveness.",
        avatar: Avatar5,
        name: 'Eduardo Verastegui',
        profession: 'Real Estate'
        },
        {
        id: 6,
        quote: "Excellent in managing projects as well as best use of technology",
        avatar: Avatar6,
        name: 'Bob Sontrop',
        profession: 'Technology Investor'
        },
        {
        id: 7,
        quote: "A company we trust",
        avatar: Avatar7,
        name: 'Christopher Brackett',
        profession: 'Director of Centre of Higher Studies, Connecticut'
        }
    ]
    
    export default data