const data = [
    {
      id: 1,
      degree_title: "Operations Manager MBA, Cesar Angulo",
      degree: "University of Arizona"
    },
    {
      id: 2,
      degree_title: "Project Coordinator, Paola Neumaier",
      degree: "Human Resourses Certified"
    },
    {
      id: 5,
      degree_title: "Quality Control Supervisor, Juan Campos",
      degree: "SAIT"
    },
    {
      id: 4,
      degree_title: "Industrial Engineer, Jacob Ruiz",
      degree: "Bachelor degree at Universidad Panamericana"
    },
    {
      id: 3,
      degree_title: "Materials Handler, Robert Solis",
      degree: "Technical in material and construction"
    },
    {
      id: 6,
      degree_title: "Admin and accountant, Constance Nunez",
      degree: "Diploma degree at Center of Highter Studies at Connecticut, USA"
    }
  ]

  export default data