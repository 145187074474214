import {SiAdobexd} from 'react-icons/si'
import{RiReactjsLine} from 'react-icons/ri'
import{FaServer} from 'react-icons/fa'
import{AiFillAppstore} from 'react-icons/ai'

const data = [
    {id: 1, icon:<AiFillAppstore/>, title:'Operations', desc:"Coordinating the order and delivery of materials to job sites, Managing a team of 10 project managers, Facilitating site planning meetings, Tracking company resource usage, Identifying project risks and proposing solutions, Dispatching subcontractors and equipment, Measuring and managing client satisfaction, Facilitating weekly operations meetings, Coordinating equipment maintenance, Keeping track of vendor pricing"},
    {id: 2, icon: <SiAdobexd/>, title:'Cleansing', desc:"Neumaier offers cleaning services ranging from cleaning construction debris to removing biohazard contaminants. We have the specialized training and products to get your property back to business."},
    {id: 3, icon:<RiReactjsLine/>, title:'Framing and Drywall', desc:"Drywall framing, including two-by-four wall studs and drywall sheets, is vital in any home building or renovation project.Knowing what is behind the drywall, the distance between the studs, and the presence of wires and pipes, makes a difference when doing anything from hanging a picture to building the room itself."},
    {id: 4, icon:<FaServer/>, title:'Electrical Services', desc:"Focus on larger projects, such as upgrading an entire floor of an office tower or installing an electrical system for a new building. We are responsible for ensuring the wiring and electrical components are up to code and work efficiently and safely."},
]

export default data