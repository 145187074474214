import Portfolio from './sections/portfolio/Portfolio';
import Header from './sections/header/Header';
import About from './sections/about/About';
import Navbar from './sections/navbar/Navbar';
import Contact from './sections/contact/Contact';
import Skills from './sections/skills/Skills';
import Education from './sections/education/Education';
import Testimonials from './sections/testimonials/Testimonials';
import Footer from './sections/footer/Footer';
import FloatingNav from './sections/floating-nav/FloatingNav';
/*import Modal from './components/Modal';*/
import Theme from './theme/Theme';
import { useThemeContext } from './context/theme-context';
/*import { useRef, useState, useEffect } from 'react'*/

const App = () => {
  const {themeState} = useThemeContext();




  return (
    <main className={`${themeState.primary} ${themeState.background}`}>
      <Navbar/>
      <Header/>
      <About/>
      <Skills/>
      <Portfolio/>
      <Testimonials/>
      <Education/>
      <Contact/>
      <Footer/>
      <Theme/>
      <FloatingNav/>
    </main>
  )
}

export default App
